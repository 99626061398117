<template>
	<div class="p-5" :style="{ width: width, 'max-width': '1024px' }">
		<div class="input-group">
			<span v-if="showGrouping" class="input-group-prepend">
				<button class="btn btn-default" @click="toggleGrouping" v-b-tooltip.hover.top :title="$t('그룹핑 방법을 변경합니다.')">
					{{ grouping.label }}
				</button>
				<b-dropdown v-b-tooltip.hover.top :title="$t('그룹핑 방법을 변경합니다.')" @click="toggleGrouping" text="" variant="light" toggle-class="btn-xs" split-variant="primary">
					<b-dropdown-item
						@click="
							useName = !useName;
							initSetting();
						"
						class="bg-white"
					>
						{{ useName ? $t("관제점명") : $t("관제점 주소") }}
					</b-dropdown-item>
				</b-dropdown>
			</span>

			<div class="form-control p-0 b-0">
				<tree-select
					v-bind="$attrs"
					v-on="inputListeners"
					:options="treeNodes"
					:multiple="false"
					:always-open="false"
					:disable-branch-nodes="true"
					:show-count="true"
					:placeholder="$t(controlMessages.POINT_TREE_PLACEHOLDER_MESSAGE)"
					:noChildrenText="$t(controlMessages.POINT_TREE_NOCHILDERN_MESSAGE)"
					:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
				>
					<div slot="value-label" slot-scope="{ node }">
						{{ node.raw.label }}
					</div>
					<label slot="option-label" slot-scope="{ node, labelClassName }" :class="labelClassName">
						{{ node.label }}
					</label>
				</tree-select>
			</div>

			<span class="input-group-append">
				<button class="btn btn-default" :disabled="!ptKey" @click="openPointModal" v-b-tooltip.hover.top :title="$t('관제점 상세')">
					<i class="fa fa-asterisk" />
					<span v-if="showPtType"> {{ curPt.ptType }} </span>
				</button>
			</span>
		</div>

		<!-- <div style="height:300px">
      ptKey : {{ ptKey }} <br />
      curPt : {{ curPt }}
    </div> -->

		<b-modal ref="pointModal" :hide-footer="true" :title="$t('관제점 상세')">
			<point-detail :ptAddr="curPt.ptAddr" :readonly="true" />
		</b-modal>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import PointDetail from "@src/views/sys/data-management/point/components/PointDetail.vue";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import * as controlMessages from "@src/consts/controlMessageConsts";	

	// TODO:  초기화값 검사해서   initVal   존재하지 않는 관제점입니다.   오류가 필요함..

	export default {
		props: {
			useIdx: {
				type: Boolean, // 값으로 ptIdx를 사용할지 여부 - true이면 ptIdx, false이면 ptAddr
				required: false,
				default: false,
			},
			initVal: {
				type: [String, Number], // 초기값 - v-model로 걸린경우 외부에서 설정한 현재값을 알 수 없어서, 초기화값 인자를 사용함.
				required: false,
				default: "",
			},
			width: {
				type: String,
				required: false,
				default: () => {
					return "100%";
				},
			},
			showGrouping: {
				type: Boolean, // 그룹별 버튼을 표시할 지 여부
				required: false,
				default: true,
			},
			showPtType: {
				type: Boolean, // 그룹별 버튼을 표시할 지 여부
				required: false,
				default: true,
			},
		},
		components: {
			PointDetail,
		},
		data() {
			return {
				useName: false, // label에 주소 혹은 이름 표시여부

				treeNodes: [],

				ptMap: {},
				ptList: [],

				ptKey: "",

				groups: [
					{ id: "ptGroup", label: this.$t("관제점") },
					{ id: "ptType", label: this.$t("취합별") },
					{ id: "Calc", label: this.$t("계산식") },
					{ id: "Facility", label: this.$t("장비별") },
				],
				groupingNo: 0,

				firstWorkflow: {},
				popupMessages,
				controlMessages,
			};
		},
		computed: {
			inputListeners() {
				let vm = this;
				return Object.assign({}, this.$listeners, {
					input: function(event) {
						vm.ptKey = event;
						vm.$emit("input", event);
					},
				});
			},
			curPt() {
				return this.ptKey ? this.ptMap[this.ptKey] || {} : {};
			},
			grouping() {
				return this.groups[this.groupingNo];
			},
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				this.ptKey = this.initVal;

				//let backEndApiFn = this.useIdx ? backEndApi.pointInfo.searchPointIM : backEndApi.pointInfo.searchPointAM;
				let backEndApiFn = this.useIdx ? backEndApi.pointInfo.searchPointIM : backEndApi.pointInfo.searchPointAM;

				backEndApiFn().then(({ data }) => {
					if (this.$err(data)) return;

					this.ptMap = data;
				});

				this.treeNodes.clear();
				this.ptList.clear();

				if (this.grouping.id == "Calc") {
					this.bindWorkflow();
				} else if (this.grouping.id == "Facility") {
					this.bindFacility();
				} else {
					this.bindPoint();
				}
			},
			bindWorkflow() {
				backEndApi.workflow.searchWorkgroups().then(({ data }) => {
					if (this.$err(data)) return;

					let workgroups = data;

					// 워크플로우 목록 조회
					backEndApi.workflow.searchAllJobs().then(({ data }) => {
						if (this.$err(data)) return;

						let workflowObjs = data;

						let workgroup = workgroups.first();

						if (this.isEmpty(workgroup)) return;

						workgroup.workflows = workgroup.workflows
							.map((name) => {
								return workflowObjs.find((workflow) => workflow.name === name);
							})
							.filter((v) => v);

						// TODO: 서비스 함수에서 현재 사용중인 workflow 조회가 간편하도록 수정해야 함.
						this.firstWorkflow = workgroup.workflows.first().workflow.children;

						let nodes = this.firstWorkflow
							.map((uplGroup, i) => {
								if (this.isEmpty(uplGroup.children)) return null;

								// TODO: upl에 ptIdx 없어서 오류남...

								let upls = uplGroup.children.map((upl) => {
									return { id: this.useIdx ? upl.ptIdx : upl.ptAddr, label: this.useName ? upl.name : upl.ptAddr };
								});

								return {
									id: "#PT_GROUP_NO_" + i,
									label: uplGroup.name,
									children: upls,
								};
							})
							.filter((v) => v);

						this.treeNodes.range(nodes);
					});
				});
			},
			bindFacility() {
				// searchFacilityMapWithPtAddr(this.facility.facilityIdx

				backEndApi.facility.searchFacilityTree().then(async ({ data }) => {
					if (this.$err(data)) return [];

					// console.log("searchFacilityTree ==> ", data);

					let facilityMaps = await backEndApi.facilityMap.searchFacilityMapWithPtAddr().then(({ data }) => {
						if (this.$err(data)) return [];
						return data;
					});

					// let dups = facilityMaps.map(p => {
					//   return facilityMaps.filter(s => s.ptAddr == p.ptAddr);
					// })

					//console.log("searchFacilityTree facilityMaps dups  ==> ", dups);

					// console.log("searchFacilityTree facilityMaps  ==> ", facilityMaps);

					let servers = data;

					let nodes = servers
						.map((server, i) => {
							if (this.isEmpty(server.children)) return;

							let channels = server.children
								.map((channel, j) => {
									if (this.isEmpty(channel.children)) return;

									let facilities = channel.children
										.map((parent, k) => {
											if (this.isEmpty(parent.children)) return;

											let subs = parent.children
												.map((sub, l) => {
													// if(this.isEmpty(sub.children)) return;

													let maps = facilityMaps
														.filter((v) => v.facilityIdx == sub.data.facilityIdx)
														.map((map) => {
															// console.log("searchFacilityTree map  ==> ", map);

															return { id: this.useIdx ? map.ptIdx : map.ptAddr, label: this.useName ? map.ptName : map.ptAddr };
														});

													// console.log("searchFacilityTree sub  ==> ", sub, maps);

													return {
														id: `#FAC_SUB_FACILITY_NO__${i}_${j}_${k}_${l}`,
														label: sub.text,
														children: maps,
													};
												})
												.filter((v) => v);

											// console.log("searchFacilityTree parent  ==> ", parent, subs);

											return {
												id: `#FAC_FACILITY_${i}_${j}_${k}`,
												label: parent.text,
												children: subs,
											};
										})
										.filter((v) => v);

									//console.log("searchFacilityTree channel  ==> ", channel, facilities);

									return {
										id: `#FAC_CHANNEL_NO_${i}_${j}`,
										label: channel.text,
										children: facilities,
									};
								})
								.filter((v) => v);

							//console.log("searchFacilityTree server  ==> ", server, channels);

							return {
								id: `#FAC_SERVER_NO_${i}`,
								label: server.text,
								children: channels,
							};
						})
						.filter((v) => v);

					console.log("searchFacilityTree nodes  ==> ", nodes);

					this.treeNodes.range(nodes);
				});
			},
			bindPoint() {
				backEndApi.pointInfo.searchPoint().then(({ data }) => {
					if (this.$err(data)) return;

					let ptList = data;

					// 맵생성
					// this.ptMap = {};
					// ptList.forEach(pt => {
					//   this.ptMap[ this.useIdx ? pt.ptIdx : pt.ptAddr ] = pt;
					// })

					// 트리 노드 생성
					let nodes = [];

					if (this.grouping.id == "ptGroup") {
						// 그룹별
						nodes = ptList
							.map((pt) => pt.ptGroup)
							.map((v) => v)
							.unique()
							.sort()
							.map((ptGroup, i) => {
								let pts = ptList
									.filter((pt) => pt.ptGroup === ptGroup)
									.map((pt) => {
										return { id: this.useIdx ? pt.ptIdx : pt.ptAddr, label: this.useName ? pt.ptName : pt.ptAddr };
									});

								return {
									id: "#PT_GROUP_NO_" + i,
									label: ptGroup,
									children: pts,
								};
							});
					} else if (this.grouping.id == "ptType") {
						// 취합유형별
						nodes = ptList
							.map((pt) => pt.ptType)
							.map((v) => v)
							.unique()
							.sort()
							.map((ptType, i) => {
								let pts = ptList
									.filter((pt) => pt.ptType === ptType)
									.map((pt) => {
										return { id: this.useIdx ? pt.ptIdx : pt.ptAddr, label: this.useName ? pt.ptName : pt.ptAddr };
									});

								return {
									id: "#PT_TYPE_NO_" + i,
									label: ptType,
									children: pts,
								};
							});
					}

					this.treeNodes.range(nodes);
				});
			},
			openPointModal() {
				this.$refs.pointModal.show();
			},
			toggleGrouping() {
				this.groupingNo = ++this.groupingNo % this.groups.length;
				this.initSetting();
			},
		},
	};
</script>
